<!--
=========================================================
* Vue Argon Dashboard 2 PRO - v3.0.1
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->

<template>
  <div v-show="layout === 'landing'" class="landing-bg h-100 bg-gradient-primary position-fixed w-100"></div>
  <sidenav v-if="showSidenav" />
  <main 
    class="main-content position-relative max-height-vh-100 h-100"
  >
    <div class="dark-light-theme-switcher">

      <button 
        v-if="current_mode != 'light'"
        class="switch-to-light dark-active" 
        @click="darkLightSwitcher('light')"
      >
        <div class="light-ui-icon">
          <svg viewBox="0 0 24 24">
            <path fill="currentColor"
              d="M3.55 19.09L4.96 20.5L6.76 18.71L5.34 17.29M12 6C8.69 6 6 8.69 6 12S8.69 18 12 18 18 15.31 18 12C18 8.68 15.31 6 12 6M20 13H23V11H20M17.24 18.71L19.04 20.5L20.45 19.09L18.66 17.29M20.45 5L19.04 3.6L17.24 5.39L18.66 6.81M13 1H11V4H13M6.76 5.39L4.96 3.6L3.55 5L5.34 6.81L6.76 5.39M1 13H4V11H1M13 20H11V23H13" />
          </svg>
          <span class="tooltiptext">Light Theme</span>
        </div>
      </button>

      <button
        v-if="current_mode == 'light'" 
        class="switch-to-dark "
        :class="{ 'light-active': current_mode == 'light' }" 
        @click="darkLightSwitcher('dark')"
      >
        <div class="dark-ui-icon">
          <svg viewBox="0 0 24 24">
            <path fill="currentColor"
              d="M17.75,4.09L15.22,6.03L16.13,9.09L13.5,7.28L10.87,9.09L11.78,6.03L9.25,4.09L12.44,4L13.5,1L14.56,4L17.75,4.09M21.25,11L19.61,12.25L20.2,14.23L18.5,13.06L16.8,14.23L17.39,12.25L15.75,11L17.81,10.95L18.5,9L19.19,10.95L21.25,11M18.97,15.95C19.8,15.87 20.69,17.05 20.16,17.8C19.84,18.25 19.5,18.67 19.08,19.07C15.17,23 8.84,23 4.94,19.07C1.03,15.17 1.03,8.83 4.94,4.93C5.34,4.53 5.76,4.17 6.21,3.85C6.96,3.32 8.14,4.21 8.06,5.04C7.79,7.9 8.75,10.87 10.95,13.06C13.14,15.26 16.1,16.22 18.97,15.95M17.33,17.97C14.5,17.81 11.7,16.64 9.53,14.5C7.36,12.31 6.2,9.5 6.04,6.68C3.23,9.82 3.34,14.64 6.35,17.66C9.37,20.67 14.19,20.78 17.33,17.97Z" />
          </svg>
          <span class="tooltiptext">Dark Theme</span>
        </div>
      </button>

    </div>
    <navbar v-if="showNavbar" />
    <router-view />
    <app-footer v-show="showFooter" />
    <configurator :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']" />
  </main>
</template>
<script>
import { ref } from "vue";
import Sidenav from "./examples/Sidenav";
import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    Sidenav,
    Configurator,
    Navbar,
    AppFooter
  },
  setup() {
    const current_mode = ref('dark');

    function darkLightSwitcher(new_mode) {
      current_mode.value = new_mode;  
      if( current_mode.value == 'light' ){
        document.body.classList.add('light-ui');
      } else {
        document.body.classList.remove('light-ui');
      }
    }

    let uri = new URL(window.location.href)
    let params = new URLSearchParams(uri.search);
    if ( params.get("m") && params.get("m") == 'light' ) {
      current_mode.value = 'light';  
      darkLightSwitcher('light');  
    }

    
    return { current_mode, darkLightSwitcher };
  },
  computed: {
    ...mapState([
      "layout",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton"
    ])
  }
};
</script>
<style>
@import "./assets/css/light-ui.css";
</style>
